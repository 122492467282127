import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { SoloArtifactsJSON } from '../../../modules/solo/data/solo-artifact-sets-data';
import { SoloJewelryJSON } from '../../../modules/solo/data/solo-jewelry-sets-data';
import { SoloArtifact } from '../../../modules/solo/common/components/solo-artifact';
import { SoloJewelry } from '../../../modules/solo/common/components/solo-jewelry';

const SoloGuidesArtSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifact Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_arti.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Artifact Sets</h1>
          <h2>List of all Artifact Sets available in Solo Leveling: Arise.</h2>
          <p>
            Last updated: <strong>19/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifact Sets" />
        <p>
          <strong>Artifacts</strong> are items in Solo Leveling: Arise that can
          be equipped on Hunters to increase their stats and if you{' '}
          <strong>
            combine the available Sets, you will unlock additional traits
          </strong>
          . These are the equivalent of Relics from Honkai: Star Rail. Each
          character has <strong>8 slots for Artifacts</strong>, but 4 of them
          are reserved for Armor and the other 4 for Jewelry.
        </p>
        <Tabs defaultActiveKey="armor" id="artifact-sets" className="mb-3">
          <Tab eventKey="armor" title="Armor Sets">
            <Row xs={1} xxl={2} className="relic-set-container">
              {SoloArtifactsJSON.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
                (arti, index) => {
                  return (
                    <Col key={index}>
                      <SoloArtifact id={arti.id} />
                    </Col>
                  );
                }
              )}
            </Row>
          </Tab>
          <Tab eventKey="jewwelry" title="Jewelry Sets">
            <Row xs={1} xxl={2} className="relic-set-container">
              {SoloJewelryJSON.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
                (arti, index) => {
                  return (
                    <Col key={index}>
                      <SoloJewelry id={arti.id} />
                    </Col>
                  );
                }
              )}
            </Row>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesArtSets;

export const Head: React.FC = () => (
  <Seo
    title="Artifact Sets | Solo Leveling: Arise | Prydwen Institute"
    description="List of all Artifact Sets available in Solo Leveling: Arise."
    game="solo"
  />
);
